import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AlertMessageService } from '@sigepe/sigepe-template';
import { UiService } from '../services/ui.service';
import { environment } from 'src/environments/environment';

/**
 * Interceptor para todos os erros de serviço do sistema, exibindo mensagem avisando caso seja um erro de comunicação com a API.
 * Fonte: https://www.ti-enxame.com/pt/angular/captura-de-erros-em-angular-httpclient/834336179/
 */

export const EXIBIR_NO_MODAL = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
	private alertMessageService = inject(AlertMessageService);
	private uiService = inject(UiService);

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const exibirNoModal = request.context.get(EXIBIR_NO_MODAL);
		if (this.urlsPermitidas(request.url)) {
			return this.exibirMensagensErro(next, request, exibirNoModal);
		} else {
			const err = new Error('test');
			throwError(() => err);
			return next.handle(request);
		}
	}

	private exibirMensagensErro(next: HttpHandler, request: HttpRequest<any>, exibirNoModal: boolean): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				this.uiService.rolarPaginaParaTopo();
				if (error.error instanceof Error) {
					const mensagens = this.converterMensagemErro(error);
					for (const mensagem of mensagens) {
						this.alertMessageService.error(
							'Erro de validação: ',
							mensagem,
							exibirNoModal ? [] : undefined,
							undefined,
							exibirNoModal ? 'modal' : undefined
						);
					}
				} else {
					this.tratarErrosPorStatus(error, exibirNoModal);
				}
				return throwError(() => error);
			})
		);
	}

	private urlsPermitidas(url: string): boolean {
		return environment.urlPermitidas.some(allowedUrl => url.startsWith(allowedUrl));
	}

	private tratarErrosPorStatus(error: HttpErrorResponse, exibirNoModal: boolean) {
		switch (error.status) {
			case 500:
				this.erroServidor();
				break;
			case 400:
			case 403:
			case 406:
				this.erroValidacao(error, exibirNoModal);
				break;
			case 404:
				this.erro404(exibirNoModal);
				break;
			default:
				this.erroDesconhecido(exibirNoModal);
				break;
		}
	}

	private erroServidor() {
		this.alertMessageService.error('Erro no servidor: ', 'Houve uma falha na comunicação com o servidor. Tente novamente mais tarde.');
	}

	private erroValidacao(error: HttpErrorResponse, exibirNoModal: boolean) {
		const mensagens = this.converterMensagemErro(error);
		for (const mensagem of mensagens) {
			this.alertMessageService.error(
				'Erro de validação: ',
				mensagem,
				exibirNoModal ? [] : undefined,
				undefined,
				exibirNoModal ? 'modal' : undefined
			);
		}
	}

	private erro404(exibirNoModal: boolean) {
		this.alertMessageService.error(
			'Não encontrado: ',
			'Item não existente ou não encontrado.',
			exibirNoModal ? [] : undefined,
			undefined,
			exibirNoModal ? 'modal' : undefined
		);
	}

	private erroDesconhecido(exibirNoModal: boolean) {
		this.alertMessageService.error(
			'Erro desconhecido: ',
			'Tente novamente mais tarde.',
			exibirNoModal ? [] : undefined,
			undefined,
			exibirNoModal ? 'modal' : undefined
		);
	}

	extrairResultadoOrdemPagamentoDTO(error: HttpErrorResponse): string[] {
		const listaErros: string[] = [];
		const erros = error.error.falhasOrdemPagamento;
		if (erros != undefined) {
			erros.array.forEach((element: any) => {
				const mms = element.mensagens;
				mms.array.forEach((item: string) => listaErros.push(item));
			});
		}
		return listaErros;
	}

	extrairResultadoNotificacaoResponsavelDTO(error: HttpErrorResponse): string[] {
		const listaErros: string[] = [];
		const erros = error.error.falhasNotificacaoResponsaveis;
		if (erros != undefined) {
			erros.array.forEach((element: any) => {
				listaErros.push(element.mensagem);
			});
		}
		return listaErros;
	}

	converterMensagemErro(error: HttpErrorResponse): string[] {
		if (error.error) {
			if (error.error.fieldErrors && error.error.fieldErrors.length > 0) {
				return this.mensagemErroDeCampos(error);
			}
			if (error.error.violations && error.error.violations.length > 0) {
				return this.mensagemErroDeViolacao(error);
			}
			if (error.error.message) {
				return [error.error.detail ?? error.error.message];
			}
			if (error.error.title === 'Bad Request') {
				return [error.error.detail];
			}
			return ['Foi encontrato um erro em ' + error.error.entityName + ' - ' + error.error.message];
		} else {
			return [error.message];
		}
	}

	private mensagemErroDeCampos(error: HttpErrorResponse): string[] {
		const fieldErrors = error.error.fieldErrors;
		const listaErros: string[] = [];
		fieldErrors.forEach((fieldError: any) => {
			listaErros.push(`${fieldError.field} ${fieldError.message}`);
		});
		return listaErros;
	}

	private mensagemErroDeViolacao(error: HttpErrorResponse): string[] {
		const violations = error.error.violations;
		const listaErros: string[] = [];
		violations.forEach((violation: any) => {
			listaErros.push(violation.message);
		});
		return listaErros;
	}
}
