import { ElementRef, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'any'
})
export class UiService {
	irParaPrimeiroCampoInvalido(el: ElementRef) {
		const firstInvalidControl: HTMLElement = el.nativeElement.querySelector('form .ng-invalid');

		if (!firstInvalidControl) return;

		window.scroll({
			top: this.getTopOffset(firstInvalidControl),
			left: 0,
			behavior: 'smooth'
		});
	}

	rolarPaginaParaTopo() {
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}, 800);
	}

	private getTopOffset(controlEl: HTMLElement): number {
		const labelOffset = 50;
		if (!controlEl) return 0;
		return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
	}
}
