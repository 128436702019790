import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AlertMessageService } from '@sigepe/sigepe-template';
import { NavegacaoService } from './shared/services/navegacao.service';
import { SegurancaService } from '@sigepe/ngx-sigepe-seguranca';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs';
import pkg from '../../package.json';

@Component({
	selector: 'sgp-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
	showNotifications = true;
	public version = pkg.version;

	constructor(
		private activatedRoute: ActivatedRoute,
		private alertMessageService: AlertMessageService,
		private router: Router,
		private segurancaService: SegurancaService,
		private titleService: Title,
		private navegacao: NavegacaoService
	) {}

	ngAfterViewInit(): void {
		this.configurarTituloPagina();
	}

	ngOnInit() {
		this.limparMensagens();
		this.navegacao.limparNavegacao();
	}

	logout() {
		this.segurancaService.logout();
	}

	private configurarTituloPagina() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			const rt = this.getChild(this.activatedRoute);
			rt.data.subscribe((data: { title: string }) => {
				this.titleService.setTitle('Gratificação v.' + this.version + ' - ' + data.title);
			});
		});
	}

	private getChild(activatedRoute: ActivatedRoute): any {
		if (activatedRoute.firstChild) {
			return this.getChild(activatedRoute.firstChild);
		} else {
			return activatedRoute;
		}
	}

	private limparMensagens() {
		this.alertMessageService.dismissAll();
	}
}
