import { LogLevel, SegurancaConfiguration } from '@sigepe/ngx-sigepe-seguranca';

export class ConfigEnvironment {
	host_gratificacao: string;
	host_eorg: string;
	host_tabelas_auxiliares: string;
	security_hosts: string[];
	host_login_unico?: string;
	host_jsks?: string;
	host_api_sigepe?: string;
	client_id?: string;
	cookieDomain?: string;

	constructor(
		host_gratificacao: string,
		host_eorg: string,
		host_tabelas_auxiliares: string,
		security_hosts: string[],
		host_login_unico?: string,
		host_jsks?: string,
		host_api_sigepe?: string,
		client_id?: string,
		cookieDomain?: string
	) {
		this.host_gratificacao = host_gratificacao;
		this.host_eorg = host_eorg;
		this.host_tabelas_auxiliares = host_tabelas_auxiliares;
		this.security_hosts = security_hosts;
		this.host_login_unico = host_login_unico;
		this.host_jsks = host_jsks;
		this.host_api_sigepe = host_api_sigepe;
		this.client_id = client_id;
		this.cookieDomain = cookieDomain;
	}

	getEnvironment() {
		return {
			DOMINIOS: `${this.host_gratificacao}/dominios`,
			EVENTOS: `${this.host_gratificacao}/eventos`,
			AUTORIZACOES_EXCEDENTES: `${this.host_gratificacao}/autorizacoes-excedentes`,
			ATIVIDADES: `${this.host_gratificacao}/atividades`,
			ATIVIDADES_SERVIDORES: `${this.host_gratificacao}/atividades-servidores`,
			DOCUMENTOS_SERVIDOR: `${this.host_gratificacao}/documentos-servidor`,
			RESPONSAVEIS_PAGAMENTO: `${this.host_gratificacao}/responsaveis-pagamento`,
			LOCAIS_ATIVIDADES: `${this.host_gratificacao}/locais-atividades`,
			PAGAMENTOS_PENDENTES: `${this.host_gratificacao}/pagamentos-pendentes`,
			REGISTRO_PAGAMENTO_SERVIDOR: `${this.host_gratificacao}/registro-pagamento-servidor`,
			SOLICITACAO_PAGAMENTO: `${this.host_gratificacao}/solicitacao-pagamento`,
			SERVIDORES_EXTERNOS: `${this.host_gratificacao}/servidores-externos`,
			TABELA_VALORES_ORGAO: `${this.host_gratificacao}/tabelas-valores-orgao`,
			BASES_CALCULO: `${this.host_gratificacao}/bases-calculo`,
			LOCALIDADE: `${this.host_gratificacao}/localidade`,
			SIAPE: `${this.host_gratificacao}/siape`,
			ORGAOS: `${this.host_eorg}/orgao`,
			UORGS: `${this.host_eorg}/uorg`,
			BANCOS: `${this.host_tabelas_auxiliares}/banco`,
			SEGURANCA_CONFIG: this.getConfiguracoesAutoriza()
		};
	}

	private getConfiguracoesAutoriza(): SegurancaConfiguration {
		const seguranca_config: SegurancaConfiguration = {
			loginUnicoHost: this.host_login_unico,
			jwksHost: this.host_jsks,
			apiSigepeHost: this.host_api_sigepe,
			redirectUrl: `${window.location.protocol}//${window.location.host}/autorizacao`,
			postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
			cookieDomain: this.cookieDomain,
			clientId: this.client_id,
			scope: 'openid+email+phone+profile+govbr_confiabilidades', // 'openid profile ' + your scopes
			sistemaOrigem: 'GRATIFICACAO',
			responseType: 'code',
			silentRenew: false,
			renewTimeBeforeTokenExpiresInSeconds: 10,
			logLevel: LogLevel.Error,
			secureRoutes: this.security_hosts
		};
		return seguranca_config;
	}
}
